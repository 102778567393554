body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page-content{
  padding-top: 0.2em;
}

.MuiTable-root{
  margin-top: 4px;
}

.app{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.flex-grow{
  flex-grow: 1;
}
.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.8);
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navBar {
  background: #d5dce2
}
