.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #79c714;
}

.gaia-font {
  font-size: 20px;
  color: #7cb137
}

.loginButton {
  color: #7cb137;
}

.green{
  color: rgb(64, 183, 89);
}

.disappear{
  animation: myAnimation infinite 0.5s;
}

@keyframes myAnimation{
  0%{
    opacity: 1;
    transform: rotateX(90deg);
  }
  100%{
    display: none;
    opacity: 0;
    transform: rotateX(90deg);
  }
}

#myelement{
    animation-name: myAnimation;
    animation-duration: 2000ms;
    animation-fill-mode: forwards;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
